import React from 'react'
import helper from '@utils/helper'

export default function ProductSchema({
    product
}) {
    const url = `https://www.tiremaxx.ca/tires/detail/${product.id}/${helper.formatTitleForLink(product.title)}`

    const schemaData = {
        "@context": "https://schema.org",
        "@type": "Product",
        "@id": url,
        "name": product.title,
        "image": product.pictures ? product.pictures.split(',') : product.pattern_picture,
        "description": product.description,
        "sku": product.sku,
        "brand": {
            "@type": "Brand",
            "name": product.brands
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": product.averageRating || 5,
            "bestRating": "5",
            "worstRating": "1",
            "reviewCount": product.reviewCount || 1
        },
        "offers": {
            "@type": "Offer",
            "priceCurrency": 'CAD',
            "price": product.price,
            "availability": "http://schema.org/InStock",
            "url": url
        }
    }

    console.log('schemaData', schemaData)

    return (
        <script type="application/ld+json">
            {JSON.stringify(schemaData)}
        </script>
    )

}
